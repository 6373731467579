/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Collapse } from 'react-collapse';

const HeaderLinks = ({ to, label, isTextDark, onClick, children }) => {
  const location = useLocation();
  const [areChildrenOpen, setAreChildrenOpen] = useState(false);
  const isAnchorLink = to.indexOf('/#');
  const _onClick = children
    ? isOpening => () => {
        setAreChildrenOpen(isOpening);
      }
    : () => onClick;

  const TheLink = isAnchorLink > 0 && location.pathname === '/' ? AnchorLink : Link;

  return (
    <li className="u-has-submenu fullscreen-submenu">
      <TheLink
        onClick={_onClick(!areChildrenOpen)}
        className="fullscreen-nav-link text-white"
        href={to.replace('/', '')}
        to={to}
      >
        {label}
      </TheLink>
      {children && (
        <ul id="docsCollapse" className="collapse show fullscreen-nav-list" data-parent="#fullscreenWrapper">
          <Collapse isOpened={areChildrenOpen}>{children}</Collapse>
        </ul>
      )}
    </li>
  );
};

HeaderLinks.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  to: PropTypes.string,
  label: PropTypes.string,
  isTextDark: PropTypes.bool,
};
export default HeaderLinks;
