import * as React from 'react';

const Logo = props => {
  return (
    <svg viewBox="0 0 500 500" {...props}>
      <circle cx={250} cy={250} r={58.48} fill="#fff" />
      <path
        d="M249.99 410.07c-88.4 0-160.07-71.67-160.07-160.09 0-88.4 71.67-160.07 160.07-160.07 88.43 0 160.08 71.67 160.08 160.07 0 88.42-71.65 160.09-160.08 160.09m0-405.76C114.31 4.31 4.32 114.32 4.32 249.98c0 135.71 109.99 245.71 245.68 245.71s245.69-110 245.69-245.71C495.68 114.32 385.68 4.31 249.99 4.31"
        fill="#3d519e"
      />
      <path
        d="M249.99 340.19c-49.8 0-90.18-40.37-90.18-90.2 0-49.79 40.38-90.19 90.18-90.19 49.83 0 90.2 40.4 90.2 90.19 0 49.83-40.37 90.2-90.2 90.2m0-250.27c-88.4 0-160.07 71.67-160.07 160.07 0 88.42 71.67 160.09 160.07 160.09 88.43 0 160.08-71.67 160.08-160.09 0-88.4-71.65-160.07-160.08-160.07"
        fill="#cadaed"
      />
      <path
        d="M249.99 284.55c-19.06 0-34.53-15.46-34.53-34.55 0-19.07 15.48-34.56 34.53-34.56 19.1 0 34.57 15.49 34.57 34.56 0 19.09-15.47 34.55-34.57 34.55m0-124.74c-49.8 0-90.18 40.38-90.18 90.2 0 49.8 40.38 90.19 90.18 90.19 49.83 0 90.2-40.38 90.2-90.19 0-49.82-40.37-90.2-90.2-90.2"
        fill="#f9e925"
      />
    </svg>
  );
};

export default Logo;
