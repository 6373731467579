import React from 'react';
import Loadable from 'react-loadable';
import Rect from 'components/Skeleton/Rect';

const GraphicDesignSection = Loadable({
  loader: () => import('./GraphicDesignSection'),
  loading: () => <Rect width={500} height={500} />,
});

export default GraphicDesignSection;
