const initialState = {
  backgroundColor: '#FFFFFF',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_BACKGROUND_COLOR':
      return { ...state, backgroundColor: payload };

    default:
      return state;
  }
};
