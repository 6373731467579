import React from 'react';
import Loadable from 'react-loadable';
import Rect from 'components/Skeleton/Rect';

const FictionSection = Loadable({
  loader: () => import('./FictionSection'),
  loading: () => <Rect width={500} height={500} />,
});

export default FictionSection;
