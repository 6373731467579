import React from 'react';
import Loadable from 'react-loadable';
import Rect from 'components/Skeleton/Rect';

const HomeHeroSection = Loadable({
  loader: () => import('./HomeHeroSection'),
  loading: () => <Rect width={500} height={500} />,
});

export default HomeHeroSection;
