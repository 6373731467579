// import 'styles/scss/index.scss'
import { Route, Switch, useLocation } from 'react-router-dom';
import Home from 'pages/Home';
import NotFound from 'pages/NotFound';
import React from 'react';
import Header from 'components/Header';
import Contact from 'pages/Contact';
import Digital from 'pages/Digital';
import GraphicDesign from 'pages/GraphicDesign';
import Fiction from 'pages/Fiction';
import Gallery from 'pages/Gallery';
import ReactGA from 'react-ga';

const Root = () => {
  const location = useLocation();
  const typeOfWindow = typeof window;

  React.useEffect(() => {
    ReactGA.set({
      page: location.pathname,
      // any data that is relevant to the user session
      // that you would like to track with google analytics
    });
    ReactGA.pageview(location.pathname); // Record a pageview for the given page

    typeOfWindow !== 'undefined' && window.scrollTo({ top: 0 });
  }, [location.pathname, typeOfWindow]);

  return (
    <div>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/digital" component={Digital} />
        <Route exact path="/graphic-design" component={GraphicDesign} />
        <Route exact path="/fiction" component={Fiction} />
        <Route exact path="/shooting" component={Gallery} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default Root;
