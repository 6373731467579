import React from 'react';
import Loadable from 'react-loadable';
import Rect from 'components/Skeleton/Rect';

const Fiction = Loadable({
  loader: () => import('./Fiction.js'),
  loading: () => <Rect width={500} height={500} />,
});

export default Fiction;
