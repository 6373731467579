import React from 'react';
import SEO from 'components/SEO';
import FacebookTypes from 'constants/facebookTypes';
import SafeAreaView from 'components/SafeAreaView';
import { useTranslation } from 'react-i18next';
import HomeHeroSection from 'containers/HomeHeroSection';
import TitleSection from 'containers/FictionSection';
import DigitalHomeSection from 'containers/DigitalHomeSection';
import { Link } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { changeColorOnVisible } from 'hooks/changeColorOnScroll';
import EventsSection from 'containers/EventsSection';
import PRHomeSection from 'containers/PRHomeSection';
import ShootingHomeSection from 'containers/ShootingHomeSection';
import GraphicDesignSection from 'containers/GraphicDesignSection';
import Footer from 'components/Footer';
import MovingPicture from 'components/MovingPicture';
import { useDispatch } from 'react-redux';

const Home = () => {
  const { t } = useTranslation();
  const disptach = useDispatch();

  return (
    <>
      <SEO
        title="Pulp Ground | Home Page"
        image="/assets/helmet/Home.jpg"
        description="In art we trust! Leverage Pulp Ground's expertise in digital marketing, graphic design, and event planning to grow your business and connect with your audience."
        type={FacebookTypes.article}
      />
      <HomeHeroSection nextSectionColor="#ff625e" />
      <TitleSection title="Fiction" />
      <SafeAreaView>
        <div id="fictionDetails" className="container position-relative">
          <div
            style={{
              minHeight: '835px',
            }}
            className="position-relative justify-content-center row"
          >
            <div className="" style={{ marginBottom: '-6rem' }}>
              <ReactVisibilitySensor
                onChange={isVisible => {
                  changeColorOnVisible('#ffbf2e', isVisible, disptach);
                }}
                partialVisibility
              >
                <div className="display-big-1 d-md-none font-weight-normal text-lh-lg text-center text-white">PULP</div>
              </ReactVisibilitySensor>
              <div className="display-big-2 d-md-none font-weight-normal text-lh-sm text-center text-white">Ground</div>
            </div>
            <MovingPicture
              maxHeight="120%"
              className="position-relative d-flex align-items-center justify-content-center col-12 col-lg-4"
              layer1="assets/images/fiction/layers/main-1.webp"
              layer2="assets/images/fiction/layers/main-2.webp"
              layer3="assets/images/fiction/layers/main-3.webp"
            />
            <div className="col-md-8 col-12 space-top-1 space-top-md-2 space-top-lg-3 ">
              <ReactVisibilitySensor
                onChange={isVisible => {
                  changeColorOnVisible('#ffbf2e', isVisible, disptach);
                }}
                partialVisibility
              >
                <div className="display-big-1 d-md-block d-none font-weight-normal text-white">PULP</div>
              </ReactVisibilitySensor>
              <div className="display-big-2 d-md-block d-none font-weight-normal text-white">Ground</div>
              <h1 className="text-white font-weight-light font-size-3">{t('fiction.1')}</h1>
            </div>
          </div>
          <Link to="/fiction">
            <div className="continue-reading text-white">
              <div>
                <span />
                {t('seeMore')}
              </div>
            </div>
          </Link>
        </div>
      </SafeAreaView>
      <TitleSection href="#digital" id="services" title="Services" subTitle="service.title" />
      <DigitalHomeSection />
      <GraphicDesignSection />
      <ShootingHomeSection />
      <EventsSection />
      <PRHomeSection />
      <Footer />
    </>
  );
};

export default Home;
