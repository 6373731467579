import React from 'react';
import Loadable from 'react-loadable';
import Rect from 'components/Skeleton/Rect';

const Digital = Loadable({
  loader: () => import('./Digital.js'),
  loading: () => <Rect width={500} height={500} />,
});

export default Digital;
