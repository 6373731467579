import CardList from 'components/CardList';
import SEO from 'components/SEO';
import FacebookTypes from 'constants/facebookTypes';
import React from 'react';

const GraphicDesign = () => {
  return (
    <>
      <SEO
        title="Pulp Ground | In Graphic Design We Trust!"
        image="/assets/helmet/GraphicDesign.jpg"
        description="What better way to communicate your messages than with visuals? Pulp Ground offers complete graphic design services to establish your brand's visual identity."
        type={FacebookTypes.article}
      />
      <CardList />
    </>
  );
};

export default GraphicDesign;
