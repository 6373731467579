import React from 'react';
import Loadable from 'react-loadable';
import Rect from 'components/Skeleton/Rect';
import HomeLoaded from './Home';

const Home = Loadable({
  loader: () => import('./Home.js'),
  loading: () => <Rect width={500} height={500} />,
});

export { Home };

export default HomeLoaded;
