import React from 'react';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useLocation } from 'react-router-dom';

const HeaderSubLinks = ({ to, label, onClick }) => {
  const location = useLocation();
  const isAnchorLink = to.indexOf('/#');
  const TheLink = isAnchorLink >= 0 && location.pathname === '/' ? AnchorLink : Link;

  return (
    <li>
      <TheLink
        onClick={onClick}
        class="nav-link text-white fullscreen-submenu-list-link"
        href={to.replace('/', '')}
        to={to}
      >
        {label}
      </TheLink>
    </li>
  );
};

HeaderSubLinks.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
};
export default HeaderSubLinks;
