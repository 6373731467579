import PropTypes from 'prop-types';
import React from 'react';

const SafeAreaView = ({ children, className }) => {
  return <div className={`space-top-1 space-top-md-2 space-top-lg-3 ${className || ''}`}>{children}</div>;
};

SafeAreaView.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
export default SafeAreaView;
