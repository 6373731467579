/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import useWindowScroll from '@react-hook/window-scroll';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import CardImage from 'components/CardImage';
import { changeColorOnVisible } from 'hooks/changeColorOnScroll';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { behanceLinks } from './behanceLinks';

const CardList = () => {
  const array = Array(18);
  const lastChild = useRef();
  const wrapper = useRef();
  const heightMaintainer = useRef();
  const screenWidth = useWindowWidth();
  const scrollY = useWindowScroll();
  const [computedHeight, setComputedHeight] = useState(0);
  const [yOffset, setYOffset] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (lastChild.current) {
      const numberOfElementsPerLine = screenWidth < 570 ? 1 : screenWidth < 1200 ? 2 : 3;
      const { width, height } = lastChild.current.getBoundingClientRect();
      const { bottom } = wrapper.current.getBoundingClientRect();

      setComputedHeight(
        (((Math.sqrt(width * width + height * height) * array.length) / numberOfElementsPerLine) * Math.PI * Math.PI) /
          16 +
          Math.abs(bottom) +
          height,
      );
    }
  }, [lastChild]);

  useEffect(() => {
    if (wrapper.current) {
      if (yOffset <= scrollY) wrapper.current.style.transform = `translate3d(0px, ${yOffset - scrollY}px, 0px)`;
    }
  }, [scrollY, wrapper]);

  return (
    <div className="position-relative">
      <ReactVisibilitySensor
        partialVisibility
        offset={{ top: 400 }}
        onChange={visibility => {
          if (visibility) setYOffset(scrollY);
          changeColorOnVisible('#ddd', visibility, dispatch);
        }}
      >
        <div ref={heightMaintainer} style={{ height: `${computedHeight}px` }} />
      </ReactVisibilitySensor>

      <div
        style={{
          top: 0,
          left: 0,
          position: 'fixed',
          transformStyle: 'preserve-3d',
          transform: 'translateX(-15vw) translateY(275px) rotateX(45deg) rotateZ(45deg)',
          width: '70vw',
          maxWidth: '1200px',
          height: 'calc(100vh - 280px)',
        }}
        className="container"
      >
        <div
          ref={wrapper}
          style={{
            transformStyle: 'preserve-3d',
          }}
          className="row mx-n2"
        >
          <h1 style={{ color: '#21325b' }} className=" col-12 display-4 mb-3  text-right bg-secondary p-2">
            Graphic Design
          </h1>
          <p style={{ color: '#21325b' }} className="col-12 d-block lead  text-right bg-secondary p-2">
            {t('service.designGraphic.1')}
          </p>
          <p style={{ color: '#21325b' }} className="col-12 d-block lead  text-right bg-secondary p-2">
            {t('service.designGraphic.2')}
          </p>
          <p style={{ color: '#21325b' }} className="col-12 d-block d-md-none lead  text-right bg-secondary p-2">
            {t('service.designGraphic.3')}
          </p>
          <p style={{ color: '#21325b' }} className="col-12 d-block d-md-none lead  text-right bg-secondary p-2">
            {t('service.designGraphic.4')}
          </p>

          {array.fill(0).map((o, i) => {
            if (i < array.length - 1)
              return (
                <div
                  className="col-sm-6 col-md-6 col-lg-6 col-xl-4 px-2 mb-3"
                  style={{ transformStyle: 'preserve-3d' }}
                >
                  <a key={`key-${i + 1}`} target="_blank" href={behanceLinks[i]}>
                    <CardImage
                      src={`/assets/images/graphic-design/${i + 1}`}
                      className="fade-out"
                      key={`key-${i + 1}`}
                    />
                  </a>
                </div>
              );

            return (
              <a key={`key-${i + 1}`} target="_blank" href={behanceLinks[i]}>
                <CardImage src={`/assets/images/graphic-design/${i + 1}`} className="fade-out" ref={lastChild} />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CardList;
