import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const FooterSection = ({ title, children }) => (
  <div className="col-lg-4 col-md-6 d-flex mx-2 justify-content-center align-items-center">
    {title && (
      <div className="mb-2 d-flex">
        <span className="font-weight-medium ">{title}</span>
      </div>
    )}
    <div className="font-size-md-2 d-flex">{children}</div>
  </div>
);

FooterSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

const Footer = () => {
  return (
    <footer className="bg-dark  text-white">
      <div className="container space-1 d-flex justify-content-between">
        <div className="col-lg-4 col-md-6 d-flex align-items-center justify-content-center">
          <Link to="/contact">
            <img src="assets/images/contact-logo.png" alt="logo" className="w-100 w-md-30" />
          </Link>
        </div>
        {/* <FooterSection title="Made with ❤ by eDonec" /> */}
        <FooterSection>
          <div>
            <a className="text-white" target="_blank" href="https://www.facebook.com/Pulp-Ground-101923445163572">
              <span className="fab fa-facebook-square mx-2 fa-lg " />
            </a>
          </div>
          <div>
            <a className="text-white" target="_blank" href="https://twitter.com/pulp_ground">
              <span className="fab fa-twitter-square mx-2 fa-lg " />
            </a>
          </div>
          <div>
            <a className="text-white" target="_blank" href="https://www.instagram.com/pulpground/">
              <span className="fab fa-instagram mx-2 fa-lg " />
            </a>
          </div>
          <div>
            <a className="text-white" target="_blank" href="https://www.behance.net/pulpground">
              <span className="fab fa-behance-square fa-lg mx-2" />
            </a>
          </div>
          <div>
            <a className="text-white" target="_blank" href="https://www.linkedin.com/company/pulpground/">
              <span className="fab fa-linkedin fa-lg mx-2" />
            </a>
          </div>
          <div>
            <a className="text-white" target="_blank" href="https://www.youtube.com/channel/UCKvf9DSQ4dcVXPZ_xQ7gfyQ">
              <span className="fab fa-youtube mx-2 fa-lg " />
            </a>
          </div>
        </FooterSection>
      </div>
      <div>
        <div className="p-5 d-flex text-center">
          <a href="https://edonec.com" target="_blank" className="font-weight-medium w-100 text-center">
            Made with ❤ by eDonec
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
