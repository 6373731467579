import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';

const Rect = ({ width, height }) => {
  return (
    <div>
      <Skeleton animation="wave" variant="rect" width={width} height={height} />
    </div>
  );
};

Rect.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
export default Rect;
