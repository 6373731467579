import { LOGIN_SUCCESS } from '_redux/types/auth';

const initialState = {
  isPredloaded: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_SUCCESS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
