/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import * as dynamics from 'dynamics.js';

const CardImage = React.forwardRef(({ className, src }, ref) => {
  const imageRef = useRef();
  const imageRef1 = useRef();
  const imageRef2 = useRef();
  const stackItemsAnimation = {
    properties: pos => ({
      translateZ: (pos + 1) * 50,
    }),
    // animation options (pos is the current subitem position); itemstotal is the total number of subitems
    options: (pos, itemstotal) => ({
      type: dynamics.bezier,
      duration: 500,
      points: [
        { x: 0, y: 0, cp: [{ x: 0.2, y: 1 }] },
        { x: 1, y: 1, cp: [{ x: 0.3, y: 1 }] },
      ],
    }),
  };
  const handleMouseOver = (out = false) => e => {
    if (out) {
      dynamics.stop(imageRef.current);
      dynamics.stop(imageRef1.current);
      dynamics.stop(imageRef2.current);
      dynamics.animate(
        imageRef.current,
        {
          translateZ: 0, // enough to reset any transform value previously set
        },
        {
          duration: 100,
        },
      );
      dynamics.animate(
        imageRef1.current,
        {
          translateZ: 0, // enough to reset any transform value previously set
        },
        {
          duration: 100,
        },
      );
      dynamics.animate(
        imageRef2.current,
        {
          translateZ: 0, // enough to reset any transform value previously set
        },
        {
          duration: 100,
        },
      );
    } else {
      dynamics.stop(imageRef.current);
      dynamics.stop(imageRef1.current);
      dynamics.stop(imageRef2.current);
      dynamics.animate(imageRef.current, stackItemsAnimation.properties(0), stackItemsAnimation.options(0, 3));
      dynamics.animate(imageRef1.current, stackItemsAnimation.properties(1), stackItemsAnimation.options(1, 3));
      dynamics.animate(imageRef2.current, stackItemsAnimation.properties(2), stackItemsAnimation.options(2, 3));
    }
  };

  return (
    <div
      ref={ref}
      onMouseOver={handleMouseOver()}
      onMouseOut={handleMouseOver(true)}
      className={`position-relative ${className}`}
      style={{
        transformStyle: 'preserve-3d',
      }}
    >
      <div
        ref={imageRef}
        className="h-250rem w-100 bg-img-hero position-relative"
        style={{ backgroundImage: `url(${src}-1.webp)` }}
      />
      <div
        ref={imageRef1}
        className="h-250rem w-100 bg-img-hero position-absolute top-0 left-0"
        style={{ backgroundImage: `url(${src}-2.webp)` }}
      />
      <div
        ref={imageRef2}
        className="h-250rem w-100 bg-img-hero position-absolute top-0 left-0"
        style={{ backgroundImage: `url(${src}-3.webp)` }}
      />
    </div>
  );
});

CardImage.propTypes = { className: PropTypes.string, src: PropTypes.string };
export default CardImage;
