import React, { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useWindowSize } from '@react-hook/window-size';
import PropTypes from 'prop-types';

const FIRST_LAYER_MULTIPLIER = 0.02;
const SECOND_LAYER_MULTIPLIER = 0.035;
const THIRD_LAYER_MULTIPLIER = 0.05;

const MovingPicture = ({ layer1, layer2, layer3, className, maxHeight = '100%' }) => {
  const firstLayerRef = useRef();
  const secondLayerRef = useRef();
  const thirdLayerRef = useRef();
  const size = useWindowSize();

  const onMouseMove = event => {
    if (isMobile) return;
    const newX = event.clientX - size[0] / 2;
    const newY = event.clientY - size[1] / 2;

    firstLayerRef.current.style.transform = `translate(${-newX * FIRST_LAYER_MULTIPLIER}px , ${
      -newY * FIRST_LAYER_MULTIPLIER
    }px)`;
    secondLayerRef.current.style.transform = `translate(${-newX * SECOND_LAYER_MULTIPLIER}px , ${
      -newY * SECOND_LAYER_MULTIPLIER
    }px)`;
    thirdLayerRef.current.style.transform = `translate(${-newX * THIRD_LAYER_MULTIPLIER}px , ${
      -newY * THIRD_LAYER_MULTIPLIER
    }px)`;
  };

  return (
    <div className={className} style={{ minHeight: 200 }} {...{ onMouseMove }}>
      <img style={{ maxHeight }} className="digital-section-image" src={layer1} alt="First Layer" ref={firstLayerRef} />
      <img
        style={{ maxHeight }}
        className="digital-section-image"
        src={layer2}
        alt="Second Layer"
        ref={secondLayerRef}
      />
      <img style={{ maxHeight }} className="digital-section-image" src={layer3} alt="Thrid Layer" ref={thirdLayerRef} />
    </div>
  );
};

MovingPicture.propTypes = {
  className: PropTypes.string,
  layer1: PropTypes.string,
  layer2: PropTypes.string,
  layer3: PropTypes.string,
  maxHeight: PropTypes.string,
};

export default MovingPicture;
