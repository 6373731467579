export const behanceLinks = [
  'https://www.behance.net/gallery/110177093/BioGa',
  'https://www.behance.net/gallery/110177551/Bloom-Works',
  'https://www.behance.net/gallery/110177819/Byrsa',
  'https://www.behance.net/gallery/110178057/EtincElle',
  'https://www.behance.net/gallery/110178305/Eye-Zone',
  'https://www.behance.net/gallery/110178483/GoTunisie',
  'https://www.behance.net/gallery/110178733/IBS-Outsourcing',
  'https://www.behance.net/gallery/110179017/La-Graine-Coffee-Shop',
  'https://www.behance.net/gallery/110179123/Le-Bab',
  'https://www.behance.net/gallery/110179297/Lemon-Beach',
  'https://www.behance.net/gallery/110179375/Melon-Pool-Bar',
  'https://www.behance.net/gallery/110289399/Olive-Longer',
  'https://www.behance.net/gallery/110289813/OryxGuide',
  'https://www.behance.net/gallery/110289959/PizzPates',
  'https://www.behance.net/gallery/110290695/RinoBooking',
  'https://www.behance.net/gallery/110290825/Saba',
  'https://www.behance.net/gallery/110290931/SkillFit',
  'https://www.behance.net/gallery/110291041/The-Factory',
  'https://www.behance.net/gallery/110310891/Breath-Salad-Bar',
  'https://www.behance.net/gallery/110310301/All-Seas-Shipping-Agency',
  'https://www.behance.net/gallery/110311655/Doodle-Burger-Bar',
  'https://www.behance.net/gallery/110312281/Cosmitto',
  'https://www.behance.net/gallery/110312553/Pulp-Ground',
  'https://www.behance.net/gallery/110312705/Pangea-Logistics-Solutions',
  'https://www.behance.net/gallery/110312911/Moncef-Jouets',
];
