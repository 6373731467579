/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'components/SVG/Logo';
import HeaderLinks from './HeaderLinks';
import HeaderSubLinks from './HeaderSubLinks';

const Header = () => {
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);

  return (
    <header id="header" className="header header-bg-transparent position-fixed header-sticky-top py-3">
      <div className="header-section navbar-expand">
        <div id="logoAndNav" className="container px-3">
          <nav className="navbar">
            <Link class="navbar-brand" to="/" style={{ width: '4rem' }} aria-label="Front">
              <Logo />
            </Link>
            <button
              id="headerToggler"
              aria-pressed={isNavBarOpen ? 'true' : 'false'}
              type="button"
              onClick={() => setIsNavBarOpen(!isNavBarOpen)}
              className={`navbar-toggler fullscreen-toggler btn btn-icon btn-sm btn-soft-white rounded-circle ${
                isNavBarOpen ? 'focus active' : ''
              }`}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-default">
                <svg width={14} height={14} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="currentColor"
                    d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z"
                  />
                </svg>
              </span>
              <span className="navbar-toggler-toggled position-fixed">
                <svg width={14} height={14} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="currentColor"
                    d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
                  />
                </svg>
              </span>
            </button>
          </nav>

          <div className={`fullscreen ${isNavBarOpen ? 'fullscreen-showed' : ''}`}>
            <div className="fullscreen-container">
              <div className="container">
                <div className="fullscreen-body">
                  <div className="row justify-content-md-between align-items-center">
                    <div id="fullscreenWrapper" className="col-md-6">
                      <ul id="fullscreenNav" className="fullscreen-nav">
                        <HeaderLinks onClick={() => setIsNavBarOpen(false)} label="Home" to="/" />
                        <HeaderLinks onClick={() => setIsNavBarOpen(false)} label="Fiction" to="/fiction" />
                        <HeaderLinks onClick={() => setIsNavBarOpen(false)} label="Services" to="/#services">
                          <HeaderSubLinks onClick={() => setIsNavBarOpen(false)} label="Digital" to="/digital" />
                          <HeaderSubLinks
                            onClick={() => setIsNavBarOpen(false)}
                            label="Graphic Design"
                            to="/graphic-design"
                          />
                          <HeaderSubLinks onClick={() => setIsNavBarOpen(false)} label="Shooting" to="/shooting" />
                          <HeaderSubLinks onClick={() => setIsNavBarOpen(false)} label="Events" to="/#events" />
                          <HeaderSubLinks onClick={() => setIsNavBarOpen(false)} label="PR" to="/#pr" />
                        </HeaderLinks>
                        <HeaderLinks onClick={() => setIsNavBarOpen(false)} label="Blog" to="/blog" />
                        <HeaderLinks onClick={() => setIsNavBarOpen(false)} label="Contact" to="/contact" />
                      </ul>
                    </div>
                    <div className="col-md-4 d-none d-md-inline-block">
                      <div className="mb-7">
                        <span className="d-block text-white font-weight-bold mb-3">Address</span>
                        <address className="mb-0">
                          <span className="d-block text-white-70 mb-1">+216 29 699 869</span>
                          <span className="d-block text-white-70 mb-1">contact@pulpground.com</span>
                          <span className="d-block text-white-70 mb-1">
                            8 Résidence Mehdi - Cité El Khalil - La Marsa - 2070 - Tunis - Tunisie
                          </span>
                        </address>
                      </div>
                      <span className="d-block text-white font-weight-bold mb-3">Social</span>

                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <a
                            className="btn btn-xs btn-icon btn-soft-light rounded-circle"
                            target="_blank"
                            href="https://www.facebook.com/Pulp-Ground-101923445163572"
                          >
                            <i className="fab fa-facebook-square mx-2 fa-lg " />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="btn btn-xs btn-icon btn-soft-light rounded-circle"
                            target="_blank"
                            href="https://twitter.com/pulp_ground"
                          >
                            <i className="fab fa-twitter-square mx-2 fa-lg " />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="btn btn-xs btn-icon btn-soft-light rounded-circle"
                            target="_blank"
                            href="https://www.instagram.com/pulpground/"
                          >
                            <i className="fab fa-instagram mx-2 fa-lg " />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="btn btn-xs btn-icon btn-soft-light rounded-circle"
                            target="_blank"
                            href="https://www.behance.net/pulpground"
                          >
                            <i className="fab fa-behance-square fa-lg mx-2" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="btn btn-xs btn-icon btn-soft-light rounded-circle"
                            target="_blank"
                            href="https://www.linkedin.com/company/pulpground/"
                          >
                            <i className="fab fa-linkedin fa-lg mx-2" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="btn btn-xs btn-icon btn-soft-light rounded-circle"
                            target="_blank"
                            href="https://www.youtube.com/channel/UCKvf9DSQ4dcVXPZ_xQ7gfyQ"
                          >
                            <span className="fab fa-youtube mx-2 fa-lg " />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fullscreen-overlay w-100 h-100" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
